import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Narumi | Designer', // e.g: 'Name | Developer'
  lang: 'ja', // e.g: en, es, fr, jp
  description: 'Narumiのポートフォリオサイト', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Portfolio of',
  name: 'Narumi',
  subtitle: 'Designer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'prof.jpg',
  paragraphOne:
    '新卒でITメガベンチャー企業へ入社。法人営業を担当する。自身のキャリアについて考える中で、キャリア教育に従事したいと考え、転職を決意。 IT教育事業を行う、株式会社divに入社。 長野への移住をきっかけにフルリモートワークを開始。同時に独学でWEBデザインの勉強を始める。',
  paragraphTwo:
    '「誰に」「何を」伝えたいデザインなのか自分の頭でも考え、自分にしかできないデザインを作り出すデザイナーになりたいです。 また、デザイン単体だけではなく、全体に目を向けられる、視野の広いデザイナーを目指しています。',
  paragraphThree:
    '経験のあるツールと言語 ▶ Illustrator、Photoshop、Figma、HTML、CSS、JavaScript、Ruby on Rails',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'web-site-1.png',
    title: 'カフェのWebサイトデザイン',
    info: '渋谷にある、非日常を提供する女性をターゲットにしたカフェの設定。',
    info2:
      '余白を多めに取ることと細いフォントでおしゃれな印象を与えることを意識して作成しています。',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'shopping_banner.png',
    title: 'ショッピングモールのバナー',
    info: '地方にある、20〜30代の流行に敏感な女性をターゲットにしたモールの設定。',
    info2: '流行りのくすんだピンクを使用、コスメで可愛さや女性らしさを訴求しています。',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'Contact',
  email: 'naru6117@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://mobile.twitter.com/mshml0',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/narumi.gto',
    },
    // {
    //  id: nanoid(),
    //  name: 'instagram',
    //  url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/naru6117',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
